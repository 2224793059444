export function formatNumber(value) {
  return new Intl.NumberFormat().format(value);
}

export function getAlphabetCharacterFromIndex(index) {
  let data = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  return data[index];
}

export function convertTimestampToDate(timestamp) {
  // return new Date(timestamp * 1000).toLocaleDateString();
  let ts_ms = timestamp * 1000;
  let date_ob = new Date(ts_ms);
  let year = date_ob.getFullYear();
  let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
  let date = ("0" + date_ob.getDate()).slice(-2);
  return year + "-" + month + "-" + date;
}

export function convertTimeToSecond(time) {
  let data_time = new Date(time);
  let hours = data_time.getHours();
  let minutes = data_time.getMinutes();
  let seconds = data_time.getSeconds();
  return hours * 60 * 60 + minutes * 60 + seconds;
}

export function convertHMToSecond(hm) {
  let a = hm.split(":"); // split it at the colons
  // minutes are worth 60 seconds. Hours are worth 60 minutes.
  return +a[0] * 60 * 60 + +a[1] * 60;
}

export function convertHMSToSecond(hm) {
  let a = hm.split(":");
  return +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
}

export function convertSecondToHM(second) {
  return new Date(second * 1000).toISOString().substr(11, 5);
}

export function convertSecondToHMS(second) {
  return new Date(second * 1000).toISOString().substr(11, 8);
}

export function convertSecondToOjbTime(second) {
  let HMS = new Date(second * 1000).toISOString().substr(11, 8);
  let arrayTime = HMS.split(":");
  let newOjbTime = new Date();
  newOjbTime.setHours(arrayTime[0]);
  newOjbTime.setMinutes(arrayTime[1]);
  newOjbTime.setSeconds(arrayTime[2]);
  return newOjbTime;
}

export function convertMSToSecond(ms) {
  let a = ms.split(":");
  return +a[0] * 60 + +a[1];
}

export function convertSecondToMS(second) {
  return new Date(second * 1000).toISOString().substr(14, 5);
}

export function convertTimestampToDMY(timestamp) {
  let ts_ms = timestamp * 1000;
  let date_ob = new Date(ts_ms);
  let year = date_ob.getFullYear();
  let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
  let date = ("0" + date_ob.getDate()).slice(-2);
  return date + "-" + month + "-" + year;
}

export function convertTimestampToDMY_HMS(timestamp) {
  let ts_ms = timestamp * 1000;
  let date_ob = new Date(ts_ms);
  let year = date_ob.getFullYear();
  let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
  let date = ("0" + date_ob.getDate()).slice(-2);
  let hours = date_ob.getHours();
  let minutes = "0" + date_ob.getMinutes();
  let seconds = "0" + date_ob.getSeconds();
  let formattedTime =
    hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
  return date + "-" + month + "-" + year + " " + formattedTime;
}

export function convertYMD_HMStoTimestamp(string) {
  return new Date(string).getTime() / 1000;
}

export function convertTimestampToYMD_HMS(timestamp) {
  let ts_ms = timestamp * 1000;
  let date_ob = new Date(ts_ms);
  let year = date_ob.getFullYear();
  let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
  let date = ("0" + date_ob.getDate()).slice(-2);
  let hours = date_ob.toString().substr(16, 2);
  let minutes = "0" + date_ob.getMinutes();
  let seconds = "0" + date_ob.getSeconds();
  let formattedTime =
    hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
  return year + "-" + month + "-" + date + " " + formattedTime;
}
